<template>
  <div>
    <v-row>
      <v-col lg="12" cols="12">
        <v-alert
          outlined
          type="success"
          text
          >
          <strong>Seja Bem Vindo, {{ $store.state.authUser.name }}!</strong>
        </v-alert>
      </v-col>
    </v-row>
  
    <v-row v-if="((this.$store.state.profile_id != 5))">
      <v-col lg="6" cols="12">
        <v-card elevation="2" class="rounded-lg">
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="">
              <strong>Total de <strong>Doadores</strong> Cadastrados</strong>
              <p>últimas horas</p>
            </div>
            <v-avatar color="green" size="60">
              <span class="white--text" size="30">{{ donor }}</span>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="6" cols="12">
        <v-card elevation="2" class="rounded-lg">
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="">
              <strong>Total de <strong>Receptores</strong> Cadastrados</strong>
              <p>últimas horas</p>
            </div>
            <v-avatar color="green" size="60">
              <span class="white--text" size="30">{{ receiver }}</span>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data: () => ({
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240],
    donor: "",
    receiver: "",
    filterUnity: "",
    receptor: "",
    doador: ""
  }),

  created(){
    this.filterUnity = this.$store.state.unity_id
    this.loading = true;

    if(this.$store.state.profile_id == 1){
      axios.get("/donor").then((response) => {
        this.loading = false;
        this.doador = response.data.data;
        this.donor = this.doador.length
      });
    }else{
      const id = this.$store.state.unity_id
      axios.get(`/donor/donorUnity/${id}`).then((response) => {
        this.loading = false;
        this.doador = response.data
        this.donor = this.doador.length
      })
    }

    if(this.$store.state.profile_id == 1){
      axios.get("/receiver").then((response) => {
        this.loading = false;
        this.receptor = response.data.data;
        this.receiver = this.receptor.length
      });
    }else{
      const id = this.$store.state.unity_id
      axios.get(`/receiver/receiverUnity/${id}`).then((response) => {
        this.loading = false;
        this.receptor = response.data
        this.receiver = this.receptor.length
      })
    }
  },

  methods: {
    indoDataTable() {
      this.loading = true;
    }
  }

  
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>
